import Button from "./button";
import "./topbar.css";
import useAuth from "../hooks/use-auth";

export default function TopBar() {
  const { profile, logout } = useAuth();

  return (
    <div className="topbar">
      <img
        className="topbar-logo"
        src="/42-plus-logo.svg"
        alt="42 Plus Logo"
        width="42"
        height="42"
      />

      {profile && (
        <Button
          label="LOG OUT"
          style="window-white"
          onClick={logout}
        ></Button>
      )}
    </div>
  );
}
