import { useEffect } from "react";
// import CF42MiniSite from "./mini-sites/cf42/cf42-mini-site";
import { usePageState } from "./mini-sites/cf42/use-page-state";
import { useParams, useLocation } from "react-router-dom";
// import { PageContext } from "../contexts";
import ProductMiniSite from "./mini-sites/product/product-mini-site";
import BetaMiniSite from "./beta-mini-site";
import { CardProvider } from "./mini-site-card";

import "./home.css";
import LoginRegisterNew from "./login-register-new";
import useAuth from "../hooks/use-auth";
import FlashMiniSite from "./mini-sites/flash/flash-mini-site";

export default function Home() {
  const { profile } = useAuth();
  const pageState = usePageState();
  const { cf42Order } = useParams();
  const location = useLocation();

  useEffect(() => {
    const isEnterPage = location.pathname === "/";
    const isAnimationPage = location.pathname.startsWith("/animation");

    pageState.setActivePage(isEnterPage ? "enter" : "home");
    pageState.setSubPage(isAnimationPage ? "pack-animation" : "none");
  }, [cf42Order, location.pathname]);

  return (
    <>
      {profile ? (
        <>
          {/* <CardProvider tabs={["Pre-Order", "Info"]} helpTab={"Info"}>
            <ProductMiniSite
              productID="dummy"
              releaseTitle="Pre-Order"
              klaviyoList="42+knithoodie"
            />
          </CardProvider> */}

          <BetaMiniSite />
          {/* <PageContext.Provider value={pageState}>
            <CF42MiniSite />
          </PageContext.Provider> */}
        </>
      ) : (
        <LoginRegisterNew />
      )}
    </>
  );
}
