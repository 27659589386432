import "./card-address-bar.css";
import { PageContext } from "../contexts";
import { useContext } from "react";
import { useCardState } from "./mini-site-card";

export default function CardAddressBar({
  onArrowClick,
  url,
  disableBackArrow,
}: {
  onArrowClick: (direction: number) => void;
  url: string;
  disableBackArrow: boolean;
}) {
  const { setSubPage } = useContext(PageContext) || {};
  const { setActiveTab, helpTab } = useCardState();

  return (
    <div className="card-address-bar">
      <div id="address-bar-controls">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#5f6368"
          onClick={() => onArrowClick(-1)}
          className={disableBackArrow ? "disabled" : ""}
        >
          <path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#5f6368"
          onClick={() => onArrowClick(1)}
          className="disabled"
        >
          <path d="M647-440H160v-80h487L423-744l57-56 320 320-320 320-57-56 224-224Z" />
        </svg>
      </div>
      <div id="address-bar-url">
        <h3>{url}</h3>
      </div>
      <div
        id="address-bar-help"
        onClick={() => {
          /* TO-DO: Limit search to target component (don't want to affect other cards) */
          if (setSubPage) setSubPage("instructions");
          if (helpTab) setActiveTab(helpTab);
          const tabs = document.querySelectorAll(".card-tab");
          tabs.forEach((t) => t.classList.remove("selected"));
          document
            .querySelector(`.card-tab-${helpTab}`)
            ?.classList.add("selected");
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#5f6368"
        >
          <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
        </svg>
      </div>
    </div>
  );
}
