import "./login-form.css";

import useAuth from "../hooks/use-auth";
import Button from "./button";
import PhoneInput from "./phone-input";
import VerifyInput from "./verify-input";

export default function LoginForm() {
  const { authStep, loading, error, otpAuth, otpVerify, getStepConfig } =
    useAuth();
  const config = getStepConfig();

  const handleSubmit = () => {
    if (authStep === "LOGIN_PHONE") {
      otpAuth(false);
    } else if (authStep === "LOGIN_OTP") {
      otpVerify();
    } else if (authStep === "LOGIN_NOT_FOUND") {
      otpAuth(true);
    }
  };

  let input;
  if (config.inputType === "phone") {
    input = <PhoneInput title={config.inputTitle} />;
  } else if (config.inputType === "verify") {
    input = <VerifyInput title={config.inputTitle} />;
  }
  if (error) {
    // console.log(error);
  }

  const generateErrorMessage = () => {
    if (error.code === "sms_send_failed") {
      return "😦 Aur Naur... Our Phone Service seems to be ghosting us.Please try again later 🙏";
    }  else if (error.code === "otp_expired") {
      return "🤔 That doesn't seem right... Please try again";
    }
    else {
      return error.message;
    }
  };

  return (
    <form className="login-form">
      <div className="header">
        <h4>{config.header}</h4>
        {config.description && <p>{config.description}</p>}
      </div>
      <div className="input-container">
        <div className="inputs">{input}</div>
        <p>{config.footer}</p>
      </div>
      {error && <p className="error-text">{generateErrorMessage()}</p>}
      <Button
        label={loading ? "Loading..." : config.buttonLabel}
        style="window-black"
        disabled={loading}
        onClick={handleSubmit}
      />
    </form>
  );
}
