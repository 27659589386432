import { useState, useCallback } from "react";

export type PageState = {
  activePage: string;
  shopifyCheckoutURL: string | null;
  subPage: string;
  isCheckoutLoading: boolean;
  isLoading: boolean;
  isModalOpen: boolean;
};

export interface PageStateHook extends PageState {
  setActivePage: (newPage: string) => void;
  setSubPage: (newSubPage: string) => void;
  setShopifyCheckoutURL: (url: string | null) => void;
  setIsLoading: (loading: boolean) => void;
  setIsModalOpen: (openModal: boolean) => void;
  setIsCheckoutLoading: (loading: boolean) => void;
}

export function usePageState(): PageStateHook {
  const [pageState, setPageState] = useState<PageState>({
    //Possible values for activePage enter, pack-animation, home, store, pack-store
    activePage: "enter",
    shopifyCheckoutURL: null,
    subPage: "none",
    isCheckoutLoading: false,
    isLoading: false,
    isModalOpen: false,
  });

  const setActivePage = useCallback((newPage: string) => {
    setPageState((prev) => ({ ...prev, activePage: newPage }));
  }, []);

  const setSubPage = useCallback((newSubPage: string) => {
    setPageState((prev) => ({ ...prev, subPage: newSubPage }));
  }, []);

  const setIsLoading = useCallback((loading: boolean) => {
    setPageState((prev) => ({ ...prev, isLoading: loading }));
  }, []);

  const setIsModalOpen = useCallback((openModal: boolean) => {
    setPageState((prev) => ({ ...prev, isModalOpen: openModal }));
  }, []);

  const setShopifyCheckoutURL = useCallback((url: string | null) => {
    setPageState((prev) => ({ ...prev, shopifyCheckoutURL: url }));
  }, []);

  const setIsCheckoutLoading = useCallback((loading: boolean) => {
    setPageState((prev) => ({ ...prev, isCheckoutLoading: loading }));
  }, []);

  return {
    ...pageState,
    setActivePage,
    setSubPage,
    setShopifyCheckoutURL,
    setIsLoading,
    setIsModalOpen,
    setIsCheckoutLoading,
  };
}
