import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { supaClient } from "../supa-client";
import { RealtimeChannel, User } from "@supabase/supabase-js";
import { useLocation } from "react-router-dom";
import { loginFormConfig, registerFormConfig } from "../utils/form-config";
import { UserProfile } from "../contexts";
import { klaviyoCreateClientSub } from "../utils";

export type AuthStep =
  | "LOGIN_PHONE"
  | "LOGIN_OTP"
  | "LOGIN_NOT_FOUND"
  | "REGISTER_PHONE"
  | "REGISTER_OTP"
  | "REGISTER_USERNAME";

export interface PhoneAuthInfo {
  countryCode: string;
  number: string;
  otp?: string;
}

interface AuthContextType {
  user?: User;
  profile?: UserProfile;
  loading: boolean;
  error?: any;
  authStep: AuthStep;
  phoneInfo: PhoneAuthInfo;
  username: string;
  otpAuth: (isSignUp: boolean) => void;
  otpVerify: () => void;
  logout: () => void;
  addProfile: (username: string) => void;
  setAuthStep: (step: AuthStep) => void;
  updatePhoneInfo: (info: Partial<PhoneAuthInfo>) => void;
  updateUsername: (username: string) => void;
  getStepConfig: () => any;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

/**
 * Custom hook that manages user session and profile state.
 * It fetches the current session, listens for authentication state changes,
 * and subscribes to real-time updates on the user's profile.
 *
 */
export function AuthProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [user, setUser] = useState<User>();
  const [profile, setProfile] = useState<UserProfile>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  const [authStep, setAuthStep] = useState<AuthStep>("LOGIN_PHONE");
  const [phoneInfo, setPhoneInfo] = useState<PhoneAuthInfo>({
    countryCode: "+1",
    number: "",
    otp: "",
  });
  const [username, setUsername] = useState<string>("");
  const [channel, setChannel] = useState<RealtimeChannel>();

  const location = useLocation();

  // Reset the error state if we change the page
  useEffect(() => {
    if (error) setError(undefined);
  }, [location.pathname]);

  useEffect(() => {
    if (error) setError(undefined);
  }, [authStep]);

  // Check for active session and fetch profile when provider mounted or user changes
  useEffect(() => {
    const fetchUserAndProfile = async () => {
      setLoadingInitial(true);
      try {
        const {
          data: { user },
        } = await supaClient.auth.getUser();
        if (user) {
          setUser(user);
          const { data: profile } = await supaClient
            .from("user_profiles")
            .select("*")
            .eq("user_id", user.id)
            .single();

          if (profile) {
            setProfile(profile);
          }

          // Set up real-time subscription to user_profiles table
          const newChannel = supaClient
            .channel(`public:user_profiles:${user.id}`)
            .on(
              "postgres_changes",
              {
                event: "*",
                schema: "public",
                table: "user_profiles",
                filter: `user_id=eq.${user.id}`,
              },
              (payload) => {
                console.log("PROFILE CHANGE", payload);
                setProfile(payload.new as UserProfile);
              },
            )
            .on("system" as any, {} as any, (_payload: any) => {
              // if (_payload.extension === "postgres_changes") {
              //   console.log("postgres-changes received");
              //   console.log(_payload);
              // }
            })
            .subscribe();

          setChannel(newChannel);
        }
      } catch (error) {
        console.error("Error fetching user and profile:", error);
        setError(error);
      } finally {
        setLoadingInitial(false);
      }
    };

    fetchUserAndProfile();

    return () => {
      // Cleanup function to unsubscribe from the channel when the component unmounts
      if (channel) {
        channel.unsubscribe();
      }
    };
  }, [user?.id]); // Dependency on user.id to re-run when user changes

  // Effect to update state in real-time for profile changes
  // Listen to changes to the profile table and update state to match
  useEffect(() => {}, [user, profile]);

  function otpAuth(isSignUp: boolean) {
    setLoading(true);
    supaClient.auth
      .signInWithOtp({
        phone: `${phoneInfo.countryCode}${phoneInfo.number}`,
        options: { shouldCreateUser: isSignUp },
      })
      .then(({ error }) => {
        if (error) {
          if (error.message === "Signups not allowed for otp") {
            setAuthStep("LOGIN_NOT_FOUND");
          } else {
            setError(error);
          }
        } else {
          setAuthStep(isSignUp ? "REGISTER_OTP" : "LOGIN_OTP");
        }
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }

  function otpVerify() {
    // console.log("Verifying: ", phoneInfo);
    setLoading(true);
    supaClient.auth
      .verifyOtp({
        phone: `${phoneInfo.countryCode}${phoneInfo.number}`,
        token: phoneInfo.otp!,
        type: "sms",
      })
      .then(({ data, error }) => {
        if (error) {
          setError(error);
        }
        if (data.user) {
          // console.log("verified", data.user);
          setUser(data.user);
          setAuthStep(
            authStep.startsWith("LOGIN") ? "LOGIN_PHONE" : "REGISTER_USERNAME",
          );
        }
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }

  function logout() {
    supaClient.auth.signOut().then(() => {
      setUser(undefined);
      setProfile(undefined);
      setAuthStep("LOGIN_PHONE");
    });
  }

  function addProfile(username: string) {
    // console.log(user)
    setLoading(true);
    supaClient
      .from("user_profiles")
      .insert([
        {
          user_id: user!.id,
          username: username,
          created_at: user!.created_at,
          phone: user!.phone!,
        },
      ])
      .eq("user_id", user!.id)
      .select()
      .single()
      .then(({ data: profile, error }) => {
        if (error) {
          setError(error);
        }
        if (profile) {
          // console.log("registered", profile.username);
          // Setting profile will make it such that the auth mini site is no longer shown
          setProfile(profile);
          klaviyoCreateClientSub(
            import.meta.env.VITE_KLAVIYO_42PLUS_LIST_ID,
            "42+Register",
            profile,
          );
        }
        setLoading(false);
        return;
      });
  }

  function updatePhoneInfo(info: Partial<PhoneAuthInfo>) {
    setPhoneInfo((prev) => ({ ...prev, ...info }));
  }

  function updateUsername(newUsername: string) {
    setUsername(newUsername);
  }

  function getStepConfig() {
    switch (authStep) {
      case "LOGIN_PHONE":
        return loginFormConfig.login_phone;
      case "LOGIN_OTP":
        return loginFormConfig.login_otp;
      case "LOGIN_NOT_FOUND":
        return loginFormConfig.login_not_found;
      case "REGISTER_PHONE":
        return registerFormConfig.register_phone;
      case "REGISTER_OTP":
        return registerFormConfig.register_otp;
      case "REGISTER_USERNAME":
        return registerFormConfig.register_username;
      default:
        throw new Error(`Unsupported auth step: ${authStep}`);
    }
  }

  // We only want to force re-renders if the user, loading or error states chage.
  const memoedValue = useMemo(
    () => ({
      user,
      profile,
      loading,
      error,
      authStep,
      phoneInfo,
      username,
      otpAuth,
      otpVerify,
      logout,
      addProfile,
      setAuthStep,
      updatePhoneInfo,
      updateUsername,
      getStepConfig,
    }),
    [user, loading, error, profile, authStep, phoneInfo, username],
  );

  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
}

// Export hook that will serve as interface to all the auth logic functions
export default function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined)
    throw Error("useAuth must be used within AuthProvider");
  return context;
}
