import { createClient, SupabaseClient } from "@supabase/supabase-js";
import { Database } from "./database";

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

export const supaClient: SupabaseClient<Database> = createClient<Database>(
  supabaseUrl,
  supabaseKey,
);
