import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

// export type CardState = {
//   activeTab: string;
// };

export interface CardContextType {
  activeTab: string;
  setActiveTab: (newTab: string) => void;
  helpTab: string;
  cardClass: string;
  setCardClass: (newClass: string) => void;
}

const CardContext = createContext<CardContextType>({} as CardContextType);
export function CardProvider({
  tabs,
  helpTab,
  children,
}: {
  tabs: string[];
  helpTab: string;
  children: ReactNode;
}): JSX.Element {
  const [activeTab, setActiveTab] = useState<string>(tabs[0]);
  const [cardClass, setCardClass] = useState<string>("");

  const memoedValue = useMemo(
    () => ({ activeTab, setActiveTab, helpTab, cardClass, setCardClass }),
    [activeTab, cardClass],
  );

  return (
    <CardContext.Provider value={memoedValue}>{children}</CardContext.Provider>
  );
}

export function useCardState() {
  const context = useContext(CardContext);
  //TODO: for backward compatibiltiy, we can't throw an error
  // until we move all minisites to use a card provider for
  // driving their tab switching
  // if (context == undefined)
  //   throw Error("useCardState must be used within CardProvider");
  return context;
}
