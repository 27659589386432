import gql from "graphql-tag";

/***
QUERIES
***/
export const ORDER_QUERY = gql`
  query FetchOrder($queryFilter: String!) {
    orders(first: 10, query: $queryFilter) {
      edges {
        node {
          id
          lineItems(first: 10) {
            edges {
              node {
                id
                image {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const PRODUCTS_QUERY = gql`
  query FetchProducts {
    edges {
      node {
        id
        title
        description
        variants(first: 1) {
          edges {
            node {
              id
              price {
                amount
                currencyCode
              }
            }
          }
        }
        images(first: 1) {
          edges {
            node {
              id
              width
              height
              url
            }
          }
        }
      }
    }
  }
`;
export interface VariantImageQueryResponse {
  data: {
    productVariant: {
      image: {
        url: string;
        id: string;
        altText: string | null;
      };
    };
  };
}
export const VARIANT_IMAGE_QUERY = gql`
  query VariantImage($variant_id: String!) {
    productVariant(id: "$variant_id") {
      image {
        url(transform: { maxWidth: 200 })
        id
        altText
      }
    }
  }
`;

/***
MUTATIONS
***/
export const CREATE_CART_MUTATION = gql`
  mutation createCart($input: CartInput!) {
    cartCreate(input: $input) {
      cart {
        id
        checkoutUrl
      }
      userErrors {
        field
        message
      }
    }
  }
`;

export const ADD_TO_CART_MUTATION = gql`
  mutation cartLinesAdd($cartId: ID!, $lines: [CartLineInput!]!) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        id
        checkoutUrl
        totalQuantity
      }
      userErrors {
        field
        message
      }
    }
  }
`;

export const REMOVE_FROM_CART_MUTATION = gql`
  mutation cartLinesRemove($cartId: ID!, $lineIds: [ID!]!) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        id
        checkoutUrl
        totalQuantity
      }
      userErrors {
        field
        message
      }
    }
  }
`;
