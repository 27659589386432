import { createContext } from "react";
import { PageStateHook } from "./components/mini-sites/cf42/use-page-state";
import { DBTypes } from "./database";
import { UserOrdersResult } from "./hooks/use-user-orders";

export type UserProfile = DBTypes["user_profiles"];

export const OrderContext = createContext<{ cf42_orders: UserOrdersResult }>(
  {} as { cf42_orders: UserOrdersResult },
);

export const PageContext = createContext<PageStateHook | null>(null);
