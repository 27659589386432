import "./login-register-new.css";
import Card from "./card";
import LoginForm from "./login-form";
import RegisterForm from "./register-form";
import useAuth from "../hooks/use-auth";
import { AuthStep } from "../hooks/use-auth";

export default function LoginRegisterNew() {
  const { authStep, setAuthStep } = useAuth();

  const isLoginState = authStep.startsWith("LOGIN_");

  const handleArrowClick = (step: number) => {
    const currentEndState = authStep.split("_")[1];
    let nextState: AuthStep;
    if (step > 0) {
      if (currentEndState === "PHONE") {
        nextState = isLoginState ? "LOGIN_OTP" : "REGISTER_OTP";
      } else if (currentEndState === "OTP") {
        nextState = isLoginState ? "LOGIN_NOT_FOUND" : "REGISTER_USERNAME";
      } else if (currentEndState === "USERNAME" && !isLoginState) {
        nextState = "REGISTER_PHONE";
      } else {
        nextState = isLoginState ? "LOGIN_PHONE" : "REGISTER_PHONE";
      }
    } else {
      if (currentEndState === "USERNAME") {
        nextState = "REGISTER_OTP";
      } else if (currentEndState === "OTP") {
        nextState = isLoginState ? "LOGIN_PHONE" : "REGISTER_PHONE";
      } else if (currentEndState === "PHONE" && !isLoginState) {
        nextState = "REGISTER_USERNAME";
      } else {
        nextState = isLoginState ? "LOGIN_PHONE" : "REGISTER_PHONE";
      }
    }
    setAuthStep(nextState);
  };

  const generateURL = () => {
    const prefix = isLoginState ? "login" : "register";
    let suffix;
    if (authStep.endsWith("PHONE")) {
      suffix = "phone";
    } else if (authStep.endsWith("OTP")) {
      suffix = "otp";
    } else if (authStep.endsWith("USERNAME")) {
      suffix = "username";
    } else {
      suffix = "😖";
    }
    return `${prefix}/${suffix}`;
  };
  return (
    <div className="login-register-new">
      <Card
        url={generateURL()}
        tabs={["login", "register"]}
        onTabClick={(tab) =>
          setAuthStep(tab === "login" ? "LOGIN_PHONE" : "REGISTER_PHONE")
        }
        onArrowClick={handleArrowClick}
        title=""
        disableBackArrow={
          authStep.endsWith("PHONE") || authStep.endsWith("USERNAME")
        }
      >
        {isLoginState ? <LoginForm /> : <RegisterForm />}
      </Card>
    </div>
  );
}
