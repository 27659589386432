import { useContext } from "react";
import Button from "../button";
import { supaClient } from "../../supa-client";
import { useCF42Asset, useShopify } from "../../hooks";
import { OrderContext } from "../../contexts";
import LoginRegisterNew from "../login-register-new";
import { klaviyoCreateClientSub } from "../../utils";
import useAuth from "../../hooks/use-auth";

const CF42_SANDBOX_FUNC = "sandbox";
export function Sandbox() {
  const { profile } = useAuth();
  const { cf42_orders } = useContext(OrderContext);
  const { mutations } = useShopify();
  // const orders = useUserOrders(profile?.user_id ?? null);

  if (import.meta.env.PROD) {
    return (
      <>
        <div>
          <h1> How'd you get here? 😦 </h1>
        </div>
      </>
    );
  }
  const fillBucket = () => {
    return (
      <Button
        label="Fill 42-plus supabse bucket"
        onClick={async () => {
          const { data: resp, error } = await supaClient.functions.invoke(
            CF42_SANDBOX_FUNC,
            {
              body: { name: "42-plus" },
            },
          );
          if (error) {
            return;
          }
          console.log("Bucket Creation success resp: ", resp);
        }}
      />
    );
  };

  const assetHookTest = () => {
    return (
      <div>
        <h2> Hooks Testing </h2>
        <div style={{ marginTop: "1rem", display: "flex", gap: "10px" }}>
          <Button
            label="Fetch CF42 Asset"
            onClick={async () => {
              const imageUrl = useCF42Asset(
                "42-plus",
                "8551589085398",
                500,
                600,
              );
              if (imageUrl) {
                const imgElement = document.createElement("img");
                imgElement.src = imageUrl;
                document.body.appendChild(imgElement);
              }
            }}
          />
          <Button
            label="Sub To Klaviyo"
            onClick={async () => {
              await klaviyoCreateClientSub(
                import.meta.env.VITE_KLAVIYO_42PLUS_LIST_ID,
                "42+ sandbox",
              );
              console.log("Another button clicked");
            }}
          />
        </div>
      </div>
    );
  };

  const ordersContextTest = () => {
    return (
      <div>
        <h2>Orders Hook Test</h2>
        <div id="ordersDisplay" style={{ marginTop: "1rem" }}>
          {cf42_orders && cf42_orders.orders.length > 0 ? (
            <ol>
              {cf42_orders.orders.map((order) => (
                <li key={order.cart_id}>
                  Cart ID: {order.cart_id || "N/A"}
                  {order.winnings === null ? (
                    " (Loading...)"
                  ) : (
                    <>
                      {" Loaded"}
                      {order.shopify_order_number &&
                        `, Order #: ${order.shopify_order_number}`}
                    </>
                  )}
                </li>
              ))}
            </ol>
          ) : (
            <h3>No orders</h3>
          )}
        </div>
      </div>
    );
  };

  const bodyFunc = () => {
    return (
      <>
        <div>
          {" "}
          <h3> you probably want to open the console [opt + ctrl + c] ;) </h3>
          <div style={{ marginTop: "1rem" }}>{fillBucket()}</div>
          <div style={{ marginTop: "1rem" }}>
            <Button
              label={"SHOPIFY BUY NOW"}
              onClick={async () => {
                const shopifyCheckoutURL = await mutations.shopifyBuy(
                  profile!.user_id,
                  "gid://shopify/ProductVariant/46170060259542",
                  import.meta.env.VITE_HOST_URL,
                );
                if (shopifyCheckoutURL) {
                  window.location.href = shopifyCheckoutURL;
                } else {
                  console.error("checkout url is not set");
                }
              }}
            />
          </div>
          {assetHookTest()}
          {ordersContextTest()}
        </div>
      </>
    );
  };
  return (
    <>
      <h1>Sandbox</h1>
      {profile ? bodyFunc() : <LoginRegisterNew />}
    </>
  );
}
