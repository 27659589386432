import "./input.css";
import useAuth from "../hooks/use-auth";

export default function PhoneInput({
  title,
}: {
  title?: string;
}) {
  const { updatePhoneInfo } = useAuth()
  return (
    <div id="phone-container">
      {/* Include country code input field to left of numbers */}
      <div className="inner-phone" id="country-code">
        <input
          className="phone-input"
          id="country-input"
          maxLength={4}
          defaultValue="+1"
          autoComplete="tel-country-code"
          onChange={(e) => {
            updatePhoneInfo({countryCode: e.target.value})
          }}
        />
      </div>
      {/* Number inputs and Title */}
      <div className="outer">
        {/* Title */}
        {title && <h6>{title}</h6>}

        {/* Number Inputs */}
        <div className="outer-phone">
          <>
            <div className="inner-phone">
              <img src={`/phone-icon.svg`} alt="email icon" />
              {/* Individual Number Input */}
              <input
                className="phone-input"
                id="register-phone-number"
                maxLength={10}
                type="text"
                autoComplete="tel-national"
                pattern="\d{10}"
                /* Verify if the input is exactly 10 numbers and enable the register button */

                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  if (value.length > 10) {
                    e.target.value = value.slice(0, 10);
                  } else {
                    e.target.value = value;
                  }
                  const isValid = (val: string) => /^\d{10}$/.test(val);
                  //TODO: Validate input FOR-139
                  // if (isValid(e.target.value)) {
                  //   updateFormCompletion(true);
                  // } else {
                  //   updateFormCompletion(false);
                  // }
                  updatePhoneInfo( {number: e.target.value})
                }}
              ></input>
            </div>
          </>
        </div>
      </div>
    </div>
  );
}
