import "./input.css";

export default function UsernameInput({
  onChange,
  title,
}: {
  onChange: (value: string) => void;
  title?: string;
}) {
  return (
    <div className="outer">
      {title && <h6>{title}</h6>}
      <div className="inner">
        <img src={`/profile-icon.svg`} alt="profile icon" />
        <input
          type={"username"}
          onChange={(e) => {
            const value = e.target.value;
            onChange(value);
          }}
        ></input>
      </div>
    </div>
  );
}
