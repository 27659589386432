import useAuth from "../hooks/use-auth";
import Button from "./button";
import PhoneInput from "./phone-input";
import VerifyInput from "./verify-input";
import UsernameInput from "./username-input";

export default function RegisterForm() {
  const {
    authStep,
    username,
    loading,
    error,
    otpAuth,
    otpVerify,
    addProfile,
    updateUsername,
    getStepConfig,
  } = useAuth();
  const config = getStepConfig();

  const handleSubmit = async () => {
    if (authStep === "REGISTER_PHONE") {
      otpAuth(true);
    } else if (authStep === "REGISTER_OTP") {
      otpVerify();
    } else if (authStep === "REGISTER_USERNAME") {
      addProfile(username);
    }
  };

  let input;
  if (config.inputType === "phone") {
    input = <PhoneInput title={config.inputTitle} />;
  } else if (config.inputType === "verify") {
    input = <VerifyInput title={config.inputTitle} />;
  } else if (config.inputType === "username") {
    input = (
      <UsernameInput
        onChange={(value: string) => updateUsername(value)}
        title={config.inputTitle}
      />
    );
  }

  return (
    <form className="login-form">
      <div className="header">
        <h4>{config.header}</h4>
        {config.description && <p>{config.description}</p>}
      </div>
      <div className="input-container">
        <div className="inputs">{input}</div>
        <p>{config.footer}</p>
      </div>
      {error && <p className="error-text">{error.message}</p>}
      <Button
        label={loading ? "Loading..." : config.buttonLabel}
        style="window-black"
        disabled={loading}
        onClick={handleSubmit}
      />
    </form>
  );
}
