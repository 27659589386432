import "./button.css";
import { useState, useEffect } from "react";

export default function Button({
  label = "Click Me",
  style = "window",
  span = "",
  onClick = null as ((e: React.MouseEvent<HTMLButtonElement>) => void) | null,
  disabled = false,
  icon = null as React.ReactNode | null,
  loading = false,
}: {
  label?: string;
  style?: string;
  span?: string;
  onClick?: ((e: React.MouseEvent<HTMLButtonElement>) => void) | null;
  disabled?: boolean;
  icon?: React.ReactNode | null;
  loading?: boolean;
}) {
  const [loadingText, setLoadingText] = useState("LOADING");

  useEffect(() => {
    if (loading) {
      let dots = "";
      const interval = setInterval(() => {
        dots = dots.length < 3 ? dots + "." : "";
        setLoadingText(`LOADING${dots}`);
      }, 1000);
      return () => clearInterval(interval); // Clean up interval on unmount
    } else {
      setLoadingText(label); // Reset to the original label when not loading
    }
  }, [loading, label]);

  if (disabled || loading) onClick = null;

  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        if (onClick) onClick(e);
      }}
      className={`button ${style} ${disabled ? "window-disabled" : ""} ${loading ? "window-loading" : ""}`}
    >
      {loading && icon ? "" : <div className="icon">{icon}</div>}
      <span className="button-subtitle">{span}</span>
      {loading ? loadingText : label}
    </button>
  );
}
