import { useState } from "react";
import { shopifyClient } from "../utils/shopify-client";
import {
  PRODUCTS_QUERY,
  REMOVE_FROM_CART_MUTATION,
  CREATE_CART_MUTATION,
  ADD_TO_CART_MUTATION,
  ORDER_QUERY,
  VARIANT_IMAGE_QUERY,
} from "../utils/shopify-queries";
import { print } from "graphql/language/printer";
import { supaClient } from "../supa-client";

export function useShopify() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const executeQuery = async (query: string, variables = {}) => {
    setIsLoading(true);
    setError(null);
    try {
      const { data, errors } = await shopifyClient.request(query, {
        variables,
      });
      if (errors) {
        console.error(errors);
        throw new Error(errors.message);
      }
      return data;
    } catch (err: any) {
      console.error(err);
      if (err.message) {
        setError(err.message);
      } else {
        setError("Bad Request");
      }

      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProducts = () => executeQuery(print(PRODUCTS_QUERY));
  const fetchOrderByCartToken = (cartToken: string) =>
    executeQuery(print(ORDER_QUERY), {
      queryFilter: `cart_token:${cartToken}`,
    });

  const fetchVariantImage = (variantId: string) =>
    executeQuery(print(VARIANT_IMAGE_QUERY), {
      variant_id: variantId,
    });

  /**
   * Fetches the URL for a checkout session in Shopify.
   *
   *  variantId - The ID of the product variant to add to the checkout.
   *  quantity - The quantity of the product variant to add to the checkout.
   *  redirectUrl - The URL to redirect to after the checkout is created.
   * - A promise that resolves to the checkout object containing the checkout ID and web URL.
   *  - Throws an error if the checkout creation fails.
   */
  const createCart = async (
    variantId: string,
    quantity: number,
    redirectUrl: string,
  ) => {
    const result = await executeQuery(print(CREATE_CART_MUTATION), {
      input: {
        lines: [{ merchandiseId: variantId, quantity }],
        attributes: [{ key: "cart_redirect_url", value: redirectUrl }],
      },
    });
    const errors = result?.cartCreate?.userErrors;
    if (errors && errors.length > 0) {
      let error_messages = "";
      errors.forEach((error: any) => {
        error_messages += error.message + " | ";
      });
      setError("😦 Shopify didn't get back to us");
      throw new Error(error_messages.trim());
    }
    return result?.cartCreate?.cart;
  };

  const addToCart = (cartId: string, variantId: string, quantity: number) =>
    executeQuery(print(ADD_TO_CART_MUTATION), {
      cartId,
      lines: [{ merchandiseId: variantId, quantity }],
    });

  const removeFromCart = (cartId: string, lineId: string) =>
    executeQuery(print(REMOVE_FROM_CART_MUTATION), {
      cartId,
      lineIds: [lineId],
    });

  const shopifyBuy = async (
    user_id: string,
    variantId: string,
    redirectUrlPrefix: string,
  ): Promise<string | null> => {
    try {
      if (!variantId) throw new Error("No variant ID found for selected pack");

      const redirect_id = crypto.randomUUID();
      // console.log(redirect_id);
      const cart = await createCart(
        variantId,
        1,
        `${redirectUrlPrefix}/${redirect_id}`,
      );
      const id_key_pair = cart.id?.split("?key=");
      if (!id_key_pair) {
        setError("😦 Shopify seems to be 'actin up'");
        throw new Error("Cart has no key");
      }
      const checkoutUrl = cart?.checkoutUrl;
      if (!checkoutUrl) {
        setError("😦 Shopify seems to be 'actin up'");
        throw new Error("Cart has no checkout URL");
      }

      const cart_record = {
        cart_id: id_key_pair[0],
        owner_user_id: user_id,
        cart_key: id_key_pair[1],
        redirect_id: redirect_id,
      };

      const { error } = await supaClient
        .from("user_carts")
        .insert([cart_record]);
      if (error) {
        //TODO: display to user why we aren't letting them checkout
        setError("😦 Our server seems to be 'buggin' ");
        console.error(error);
        throw new Error("Unable to insert new cart record to DB");
      }

      return checkoutUrl;
    } catch (error) {
      console.error("Error creating checkout:", error);
      return null;
    }
  };

  return {
    queries: {
      fetchProducts,
      fetchOrderByCartToken,
      fetchVariantImage,
    },
    mutations: {
      createCart,
      addToCart,
      removeFromCart,
      shopifyBuy,
    },
    states: {
      isLoading,
      error,
    },
  };
}
