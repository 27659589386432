type FormConfig = {
  header: string;
  inputTitle: string;
  inputType: string;
  inputPlaceholder: string;
  inputLength: number;
  country: boolean;
  footer: string;
  buttonLabel: string;
  error: string;
  description?: string;
};

type LoginFormConfig = {
  login_phone: FormConfig;
  login_otp: FormConfig;
  login_not_found: FormConfig;
};

type RegisterFormConfig = {
  register_phone: FormConfig;
  register_otp: FormConfig;
  register_username: FormConfig;
};

export const loginFormConfig: LoginFormConfig = {
  login_phone: {
    header: "Login with Phone Number.",
    inputTitle: "Phone Number",
    inputType: "phone",
    inputPlaceholder: "4077470791",
    inputLength: 10,
    country: true,
    footer: `Standard messaging rates will apply. View our terms and conditions for more details`,
    buttonLabel: "SEND CODE",
    error: "ERROR: Invalid Phone Number",
  },

  login_otp: {
    header: "OTP Verification",
    description: "Enter the 6-digit code sent to",
    inputTitle: "OTP Verification",
    inputType: "verify",
    inputPlaceholder: "4077470791",
    inputLength: 6,
    country: true,
    footer: ``,
    buttonLabel: "LOGIN",
    error: "ERROR: Invalid Verification Code",
  },

  login_not_found: {
    header: "Account Not Found.",
    description:
      "This phone number is not currently registered. Proceed to registration?",
    inputTitle: "",
    inputType: "",
    inputPlaceholder: "",
    inputLength: 6,
    country: true,
    footer: ``,
    buttonLabel: "REGISTER NOW",
    error: "ERROR: Invalid Verification Code",
  },
};

export const registerFormConfig: RegisterFormConfig = {
  register_phone: {
    header: "Register with Phone Number.",
    inputTitle: "Phone Number",
    inputType: "phone",
    inputPlaceholder: "4077470791",
    inputLength: 10,
    country: true,
    footer: `Standard messaging rates will apply. View our terms and conditions for more details`,
    buttonLabel: "SEND CODE",
    error: "ERROR: Invalid Phone Number",
  },

  register_otp: {
    header: "OTP Verification",
    description: "Enter the 6-digit code sent to",
    inputTitle: "OTP Verification",
    inputType: "verify",
    inputPlaceholder: "4077470791",
    inputLength: 6,
    country: true,
    footer: ``,
    buttonLabel: "VERIFY",
    error: "ERROR: Invalid Verification Code",
  },

  register_username: {
    header: "Registration Complete",
    description: "Welcome to 42+. Set your account username below.",
    inputTitle: "Username",
    inputType: "username",
    inputPlaceholder: "npcmilo",
    inputLength: -1,
    country: true,
    footer: ``,
    buttonLabel: "ENTER 42+",
    error: "ERROR: Username is already taken",
  },
};
