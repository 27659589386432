import { UserProfile } from "../contexts";
import * as Sentry from "@sentry/react";

export async function klaviyoCreateClientSub(
  listId: string,
  subSource: string,
  profile?: UserProfile,
  properties?: { [key: string]: string },
  patchPropertiesAppend: { [key: string]: string } = {},
  patchPropertiesUnappend: { [key: string]: string } = {},
) {
  if (!profile) return;
  const url = `https://a.klaviyo.com/client/subscriptions/?company_id=${import.meta.env.VITE_KLAVIYO_PUBLIC_KEY}`;
  // console.log(
  //   `using url ${url} and revision ${import.meta.env.VITE_KLAVIYO_REVISION}`,
  // );
  const options = {
    method: "POST",
    headers: {
      revision: import.meta.env.VITE_KLAVIYO_REVISION,
      "content-type": "application/json",
    },
    body: JSON.stringify({
      data: {
        type: "subscription",
        attributes: {
          profile: {
            data: {
              type: "profile",
              attributes: {
                properties: {
                  fPlusUserName: profile.username,
                  fPlusId: profile.user_id,
                  ...(properties ? properties : {}),
                },
                meta: {
                  patch_properties: {
                    append: {
                      ...patchPropertiesAppend,
                    },
                    unappend: {
                      ...patchPropertiesUnappend,
                    },
                  },
                },
                phone_number: `+${profile.phone}`,
                external_id: profile.user_id,
              },
            },
          },
          custom_source: subSource,
        },
        relationships: { list: { data: { type: "list", id: listId } } },
      },
    }),
  };
  const response = await fetch(url, options);
  console.log(response);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
}

export async function klaviyoUpdateProfProps(
  phone: string,
  product_handle: string,
  patchPropertiesAppend: { [key: string]: string } = {},
  patchPropertiesUnappend: { [key: string]: string } = {},
) {
  const url = `https://a.klaviyo.com/client/events/?company_id=${import.meta.env.VITE_KLAVIYO_PUBLIC_KEY}`;
  const options = {
    method: "POST",
    headers: {
      accept: "application/json",
      revision: import.meta.env.VITE_KLAVIYO_REVISION,
      "content-type": "application/json",
    },
    body: JSON.stringify({
      data: {
        type: "event",
        attributes: {
          properties: {},
          metric: {
            data: {
              type: "metric",
              attributes: {
                name: "Product Mini Site Sub",
                service: product_handle,
              },
            },
          },
          profile: {
            data: {
              type: "profile",
              attributes: {
                meta: {
                  patch_properties: {
                    append: {
                      ...patchPropertiesAppend,
                    },
                    unappend: {
                      ...patchPropertiesUnappend,
                    },
                  },
                },
                phone_number: `+${phone}`,
              },
            },
          },
        },
      },
    }),
  };
  const response = await fetch(url, options);
  console.log(response);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
}
