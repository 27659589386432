import CardAddressBar from "./card-address-bar";
import "./card.css";

import { useCardState } from "./mini-site-card";

export default function Card({
  title = "[Insert Title]",
  img,
  children,
  tabs: tabNames,
  onTabClick,
  onArrowClick = () => {},
  disableBackArrow,
  videoURL,
  url,
}: {
  title?: string;
  img?: string;
  children?: React.ReactNode;
  tabs?: string[];
  onTabClick?: (tab: string) => void;
  onArrowClick: (direction: number) => void;
  disableBackArrow?: boolean;
  videoURL?: string;
  url?: string;
}) {
  const { cardClass, setActiveTab } = useCardState() || {};

  const tabHeader = (tabs: string[], onTabClick) => {
    const handleTabClick = (tab: string) => {
      /* TO-DO: Limit search to target component (don't want to affect other cards) */
      const tabs = document.querySelectorAll(".card-tab");
      tabs.forEach((t) => t.classList.remove("selected"));
      document.querySelector(`.card-tab-${tab}`)?.classList.add("selected");
      onTabClick(tab);
    };

    return (
      <div className="card-tab-header">
        {tabs &&
          tabs.map((tab, index) => (
            <a
              key={tab}
              className={`card-tab card-tab-${tab} ${
                index === 0 ? "selected" : ""
              }`}
              onClick={() => {
                setActiveTab && setActiveTab(tab);
                handleTabClick(tab);
              }}
              style={{ cursor: "pointer" }}
            >
              <h3>{tab.charAt(0).toUpperCase() + tab.slice(1)}</h3>
            </a>
          ))}
      </div>
    );
  };

  return (
    <div className="window-card">
      {/* Card Header */}

      <div className="card-header">
        <div className="window-card-header">
          {onTabClick && tabHeader(tabNames, onTabClick)}
          {title && <h3>{title}</h3>}
          <div className="window-card-controls">
            <div className="circle card-exit"></div>
            <div className="circle card-minimize"></div>
            {/* Testing window card interactivity */}
            <div className="circle card-maximize"></div>
          </div>
        </div>
        {url && (
          <CardAddressBar
            onArrowClick={onArrowClick}
            url={"42+/" + url}
            disableBackArrow={
              disableBackArrow === undefined ? true : disableBackArrow
            }
          ></CardAddressBar>
        )}
      </div>

      <div className={`window-card-body ${cardClass}`}>
        {videoURL && (
          <video
            className="window-card-video-bg"
            src={videoURL}
            autoPlay
            loop
            muted
          ></video>
        )}
        {children}
        {img && <img className="window-card-img" src={img}></img>}
      </div>
    </div>
  );
}
