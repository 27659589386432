import "./inline-input.css";

export default function InlineInput({
  type,
  form,
  index,
}: {
  type: string;
  form: string;
  index: number;
}) {
  return (
    <div className={`inner-${type}`}>
      {/* Individual Number Input */}
      <input
        className={`${type}-input`}
        id={`${form}-${type}-number-${index}`}
        onInput={(e: React.FormEvent<HTMLInputElement>) => {
          const target = e.target as HTMLInputElement;
          target.value = target.value.slice(0, 1);
        }}
        autoComplete={index === 1 ? "one-time-code" : "off"}
        type="text"
        inputMode="numeric"
        pattern="\d{1}"
        maxLength={1}
        /* Verify if the input is a single digit and enable the register button */

        onChange={(e) => {
          const isValid = (val: string) => /^\d{1}$/.test(val);
          if (isValid(e.target.value)) {
            if (type === "verify") {
              const nextInputId = `${form}-${type}-number-${index + 1}`;
              const nextSibling = document.getElementById(nextInputId);

              if (nextSibling) {
                nextSibling.focus();
              }
            }
          } else if (e.target.value === "") {
            let previousIndex = index - 1;
            while (previousIndex > 0) {
              const previousInputId = `${form}-${type}-number-${previousIndex}`;
              const previousSibling = document.getElementById(
                previousInputId,
              ) as HTMLInputElement;
              if (previousSibling && previousSibling.value !== "") {
                previousSibling.focus();
                break;
              }
              previousIndex--;
            }
          }
        }}
        onKeyDown={(e) => {
          const target = e.target as HTMLInputElement;
          if (e.key === "Backspace" && target.value === "") {
            const previousInputId = `${form}-${type}-number-${index - 1}`;
            const previousSibling = document.getElementById(
              previousInputId,
            ) as HTMLInputElement;
            if (previousSibling) {
              previousSibling.focus();
              previousSibling.value = "";
            }
          }
        }}
      />
    </div>
  );
}
