import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import { AuthProvider } from "./hooks/use-auth";

/* Component Imports */
import Footer from "./components/footer";
import Home from "./components/home";
import TopBar from "./components/topbar";
import { Sandbox } from "./components/sandbox";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
        children: [
          {
            path: "animation/:redirectId",
            element: <Home />,
          },
          {
            path: "home",
            element: <Home />,
          },
        ],
      },
      {
        path: "sandbox",
        element: <Sandbox />,
      },
    ],
  },
]);

function App() {
  // console.log("app entry")
  return <RouterProvider router={router} />;
}

export default App;

function Layout() {
  return (
    <>
      <AuthProvider>
        <TopBar />
        <div className="main">
          <Outlet />
        </div>
        <Footer></Footer>
      </AuthProvider>
    </>
  );
}
